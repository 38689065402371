// blog data

export const Blogs = [
  {
    id: 0,
    name: 'Framed by Evelina',
    tags: ['React', 'NextJS', 'TailwindCSS', 'Shadcn/ui'],
    date: 'My Photography Website. Still in progress',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/framedByEvelina.jpg?raw=true',
    link: 'https://framedbyevelina.netlify.app/istorijos',
    target: '_blank',
  },

  {
    id: 1,
    name: 'Explore and More',
    tags: [
      'Gatsby',
      'React',
      'Contentful',
      'GraphQL',
      'Headless CMS',
      'Responsive',
      'Headless UI',
      'Typescript',
      'i18n',
    ],
    date: 'My project about authentic places. Still in progress..',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/photogenic-places.jpg?raw=true',
    link: 'https://exploreandmoretravel.netlify.app/',
    target: '_blank',
  },
  {
    id: 2,
    name: 'Ratepunk landing page',
    tags: [
      'NextJS',
      'React',
      'Typescript',
      'Front-End',
      'SASS Modules',
      'Responsive',
    ],
    date: 'Single page with form validation and responsive design.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/ratepunk.gif?raw=true',
    link: 'https://ratepunk-landing-page.netlify.app/',
    target: '_blank',
  },
  {
    id: 3,
    name: 'Projects Library',
    tags: ['Vue2', 'Vuetify', 'Design', 'UI/UX', 'FrontEnd'],
    date: 'Web aplication for storing testing projects. All design, workflows created by me.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/project-repository.gif?raw=true',
    link: 'https://estaniulyte.github.io/projects-library/',
    target: '_blank',
  },
  {
    id: 4,
    name: 'WEHR',
    tags: ['Vue3', 'Typescript', 'CSS', 'Responsive', 'Figma Desgin'],
    date: 'Responsive design created from Figma file.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/weHR.png?raw=true',
    link: 'https://estaniulyte.github.io/hr-dashboard/',
    target: '_blank',
  },
  {
    id: 11,
    name: 'Admin Template',
    tags: ['Vue3', 'Typescript', 'TailwindCSS', 'Figma'],
    date: 'FE position task',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/salesDashboard.jpg?raw=true',
    link: 'https://admin-template-reiztech.netlify.app/',
    target: '_blank',
  },

  {
    id: 5,
    name: 'Team Space',
    tags: ['React', 'BEM', 'SCSS', 'CSS', 'HTML', 'JS', 'FrontEnd'],
    date: 'Website made with React. Project created during Sourcery academy for Front-End',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/devbriidge.gif?raw=true',
    link: 'https://estaniulyte.github.io/sourcery-academy-front-end/',
    target: '_blank',
  },
  {
    id: 7,
    name: 'Space Calligraphy Game',
    tags: ['unity', 'mobile', 'game', 'design', 'illustrator'],
    date: 'A mobile game made with Unity. All visuals are created using Illustrator.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/cosmos.jpg?raw=true',
    link: 'https://play.google.com/store/apps/details?id=com.fluffycloud.cosmoscalligraphy',
    target: '_blank',
  },
  {
    id: 8,
    name: 'KiDrive #Hack4Vilnius',
    tags: ['Figma', 'UI/UX', 'Prototype', 'Hackaton', 'Weekend'],
    date: 'Hackaton project done with Figma. KiDrive - mobile app to help parents transport children to school and back. App algorithms would calculate routes for drivers based on the data entered by the parents.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/kidrive.jpg?raw=true',
    link: 'https://www.figma.com/proto/Bgo5QjQOFEn5u54XSGrm8P/KiDrive?type=design&node-id=1-2444&t=6PwZE9tc2AvpRDtk-1&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A2444&show-proto-sidebar=1&mode=design',
    target: '_blank',
  },
  {
    id: 13,
    name: 'FE task',
    tags: ['Vue2', 'Typescript', 'Vuetify', 'i18n', 'Nuxt', 'Jest'],
    date: 'Task for FE position with Vue.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/vueDashboard.jpg?raw=true',
    link: 'https://products-dashboard-store.netlify.app/',
    target: '_blank',
  },
  {
    id: 14,
    name: 'Games Dashboard',
    tags: ['React', 'Typescript', 'Chakra-UI', 'axios'],
    date: 'Learning project',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/gamesDashboard.jpg?raw=true',
    link: 'https://games-dashboard.netlify.app/',
    target: '_blank',
  },
  {
    id: 6,
    name: 'Exus FEE task',
    tags: [
      'React',
      'Typescript',
      'TailwindCSS',
      'Dokerfile',
      'API requests',
      'Axios',
      'FrontEnd',
    ],
    date: 'Web application which depicts information about organizations, the projects they participate and users.',
    imgSrc:
      'https://github.com/estaniulyte/photos/blob/main/portfolioWebsite/projects/exus.gif?raw=true',
    link: 'https://github.com/estaniulyte/exus-fee-task',
    target: '_blank',
  },
  // {
  //   id:3,
  //   name:"Cafe Website",
  //   tags:["FrontEnd","ResponsiveDesign","React","BEM","SCSS","JS"],
  //   date:"Learning project. In progress.",
  //   imgSrc:"https://drive.google.com/uc?export=download&id=1zhb43SqMv5PHUsbI8i9vFxAsltS8WZGd",
  //   link:"https://estaniulyte.github.io/coffee-shop/",
  //   target: "_blank"
  // },
  // {
  //   id:4,
  //   name:"Job search app | UI/UX courses",
  //   tags:["Figma","UI/UX","Design","InVision", "Balsamiq"],
  //   date:"UI/UX process creating Job Searching application",
  //   imgSrc:"https://drive.google.com/uc?export=download&id=1SvPNWwYeeNusAMn-tQ99D7h96_vDJPrb",
  //   link:"https://evelinastaniulyte722314.invisionapp.com/public/prototype/sks5o8fvp008gc601y2pdggyl/3cfe6a04",
  //   target: "_blank"
  // },
  // {
  //   id: 9,
  //   name: 'Photography',
  //   tags: ['Lightroom', 'Photoshop', 'Canon'],
  //   date: '',
  //   imgSrc:
  //     'https://drive.google.com/uc?export=download&id=16AQ146_g2BNlJpg4kVChzFR-YnSWQCKz',
  //   link: '/photography',
  //   target: '',
  // },
  // {
  //   id: 10,
  //   name: 'Illustrations',
  //   tags: ['Illustrator', 'Photoshop'],
  //   date: '',
  //   imgSrc:
  //     'https://drive.google.com/uc?export=download&id=1T0l99lliYeoh02wN3rBw-JoYPLpXurLV',
  //   link: '/graphic-design',
  // },
];
