import React from 'react';
import styled, { keyframes, ThemeProvider } from 'styled-components';
import { DarkTheme } from './Themes';
import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import BigTitle from '../subComponents/BigTitlte';
import astronaut from '../assets/Images/dev-female.png';
import AboutParticleComponent from '../subComponents/AboutParticleComponent';

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;
const float = keyframes`
  0% { transform: translateY(-10px) }
  50% { transform: translateY(15px) translateX(15px) }
  100% { transform: translateY(-10px) }
`;
const Spaceman = styled.div`
  position: absolute;
  top: 20%;
  right: 9%;
  width: 20vw;
  animation: ${float} 4s ease infinite;
  img {
    width: 100%;
    height: auto;
    transform: rotate(-8deg);
  }
`;
const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  box-sizing: border-box;
  width: 50vw;
  height: 78vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: calc(1.3vw);
  backdrop-filter: blur(4px);
  position: absolute;
  left: calc(6rem + 5vw);
  top: 8rem;
  font-family: Inter, Roboto;
  overflow: scroll;
  padding-top: 15rem;

  @media (max-width: 600px) {
    font-size: calc(3.6vw);
    padding: 18rem 1rem 2rem 1rem;
    width: 80vw;
    height: 80vh;
    left: calc(1.7rem + 5vw);
    overflow: scroll;
  }

  .box {
    padding: 5px 8px;
    margin: 4px;
    padding: 12px;
    margin-right: 8px;
    font-size: calc(0.8vw);
    cursor: default;

    @media (max-width: 600px) {
      padding: 5px 5px;
      font-size: calc(3vw);
      margin-right: 1px;
    }
  }

  .color1 {
    background-color: #a32929;

    &:hover {
      transform: rotate(5deg);
    }
  }

  .color2 {
    background-color: #a34a29;

    &:hover {
      transform: rotate(-8deg);
    }
  }

  .color3 {
    background-color: #a36a29;

    &:hover {
      transform: rotate(-3deg);
    }
  }

  .color4 {
    background-color: #a38a29;

    &:hover {
      transform: rotate(9deg);
    }
  }

  .color5 {
    background-color: #98a329;

    &:hover {
      transform: rotate(-4deg);
    }
  }

  .color6 {
    background-color: #77a329;

    &:hover {
      transform: rotate(14deg);
    }
  }

  .color7 {
    background-color: #55a329;

    &:hover {
      transform: rotate(-10deg);
    }
  }

  .color8 {
    background-color: #34a329;

    &:hover {
      transform: rotate(-3deg);
    }
  }

  .color9 {
    background-color: #29a33f;

    &:hover {
      transform: rotate(-12deg);
    }
  }

  .color10 {
    background-color: #29a360;

    &:hover {
      transform: rotate(-5deg);
    }
  }

  .color11 {
    background-color: #29a382;

    &:hover {
      transform: rotate(-7deg);
    }
  }

  .color12 {
    background-color: #29a3a3;

    &:hover {
      transform: rotate(-4deg);
    }
  }

  .color13 {
    background-color: #2982a3;

    &:hover {
      transform: rotate(10deg);
    }
  }

  .color14 {
    background-color: #2960a3;

    &:hover {
      transform: rotate(-7deg);
    }
  }

  .color15 {
    background-color: #293fa3;

    &:hover {
      transform: rotate(11deg);
    }
  }

  .color16 {
    background-color: #3429a3;

    &:hover {
      transform: rotate(-8deg);
    }
  }

  .color17 {
    background-color: #5529a3;

    &:hover {
      transform: rotate(9deg);
    }
  }

  .color18 {
    background-color: #7729a3;

    &:hover {
      transform: rotate(-8deg);
    }
  }

  .color19 {
    background-color: #9829a3;

    &:hover {
      transform: rotate(-13deg);
    }
  }

  .color20 {
    background-color: #a32980;

    &:hover {
      transform: rotate(10deg);
    }
  }

  .color21 {
    background-color: #a3296c;

    &:hover {
      transform: rotate(-10deg);
    }
  }

  .color22 {
    background-color: #a32947;

    &:hover {
      transform: rotate(10deg);
    }
  }

  .color23 {
    background-color: #a32929;

    &:hover {
      transform: rotate(-10deg);
    }
  }
  .inline-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    margin-top: 7px;
    padding: 0;
    text-align: left;
    align-items: left;
    flex-wrap: wrap;
  }
`;

const AboutPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme='dark' />
        <SocialIcons theme='dark' />
        <AboutParticleComponent />
        <Spaceman>
          <img src={astronaut} alt='spaceman' />
        </Spaceman>
        <Main>
          <p>
            Hi! I'm Evelina, a <b>Front-End</b> developer and UI/UX enshusiast
          </p>
          <br />
          <p>
            I'm an engineer dedicated to delivering exceptional user experiences
            and ensuring aesthetically pleasing look. With a strong focus on UX,
            I prioritize the end user in every system decision, while my QA
            experience and mindset drive the creation of high-quality software.
          </p>
          <br />
          <p>
            With a background at Unity Technologies and Barbora, I bring a
            wealth of experience from leading companies in their respective
            fields. I am confident that my diverse expertise will inject fresh
            perspectives into your team.{' '}
          </p>
          <br />
          <p>
            As an individual, I am proactive and maintain a positive outlook.
            Being a team player, I thrive on sharing experiences and learning
            from my peers. I firmly believe that fostering a strong team spirit
            not only enables us to achieve ambitious goals but also sustains a
            healthy work environment.
          </p>
          <br />
          <p>
            I finished bachelor studies of Multimedia and Computer design, later
            UI/UX courses, Black Box testing foundations and the latest is
            Front-End academy. The learning path doesn't end here, because a
            part of leisure time I spend on improving coding skills.
          </p>
          <br />
          Things I have experience with:
          <br />
          <div className='inline-box'>
            <div className='color1 box'>Remix</div>
            <div className='color2 box'>React</div>
            <div className='color4 box'>Vue</div>
            <div className='color5 box'>Next.JS</div>
            <div className='color6 box'>Tailwind CSS</div>
            <div className='color7 box'>SASS, CSS</div>
            <div className='color8 box'>NodeJS</div>
            <div className='color9 box'>GIT</div>
          </div>
          <div className='inline-box'>
            <div className='color10 box'>Typescript</div>
            <div className='color11 box'>Figma</div>
            <div className='color12 box'>Shadcn/ui</div>
            <div className='color13 box'>JIRA</div>
            <div className='color14 box'>Jest</div>
            <div className='color15 box'>SQL</div>
            <div className='color16 box'>Weblate</div>
          </div>
          <div className='inline-box'>
            <div className='color17 box'>Unity</div>
            <div className='color18 box'>GitLab</div>
            <div className='color19 box'>Bitbucket</div>
            <div className='color20 box'>Docker</div>
            <div className='color21 box'>GraphQL</div>
            <div className='color22 box'>Prisma</div>
            <div className='color23 box'>Kubernetes</div>
          </div>
          <br />
          <p>
            Outside of work, I enjoy practising <b>dances</b> like Salsa and
            other Latino styles. Also, I fond of <b>reading</b> (currently
            "Inspired" by Marty Cagan) and spend much time{' '}
            <b>taking pictures</b> and even more on retouching them.
          </p>
        </Main>
        <BigTitle text='ABOUT' top='10%' left='5%' />
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
