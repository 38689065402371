import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons from '../subComponents/SocialIcons'
import Intro from './Intro'

const MainContainer = styled.div`
  background: ${props => props.theme.body};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  h2, h3, h4, h5, h6 {
    font-family: 'Karla', sans-serif;
    font-weight: 500;
  }
`

const Container = styled.div`
  padding: 2rem;

  @media (max-width: 900px) {
    padding: 0;
  }
`

const Contact = styled(NavLink)`
  color: ${props => props.theme.text};
  position: absolute;
  top: 2rem;
  right: calc(1rem + 2vw);
  text-decoration: none;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    color: ${props => props.theme.body};
  }
`
const BLOG = styled(NavLink)`
  color: ${props => props.theme.text};
  position: absolute;
  top: 50%;
  right: calc(1rem + 2vw);
  transform: rotate(90deg) translate(-50%, -50%);
  text-decoration: none;
  z-index:1;

  @media only screen and (max-width: 600px) {
    color: ${props => props.theme.body};
    text-shadow: 2px 2px 4px #000;
    top: 43%;
    right: calc(1vw);
  }
`
const WORK = styled(NavLink)`
  color: ${props => props.click ? props.theme.body : props.theme.text};
  position: absolute;
  top: 50%;
  left: calc(1rem + 2vw);
  transform: translate(-50%, -50%) rotate(-90deg) ;
  text-decoration: none;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    color: ${props => props.theme.body};
    text-shadow: 2px 2px 4px #000;
    top: 40%;
  }

`

const BottomBar = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 600px) {
    bottom: 9rem;
  }
`

const ABOUT = styled(NavLink)`
  color: ${props => props.click ? props.theme.body : props.theme.text};
  text-decoration: none;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    color: ${props => props.click ? props.theme.text : props.theme.body};
  }
`
const SKILLS = styled(NavLink)`
  color: ${props => props.theme.text};
  text-decoration: none;
  z-index:1;

  @media only screen and (max-width: 600px) {
    color: ${props => props.click ? props.theme.body : props.theme.text};
  }

`

// const rotate = keyframes`
//   from {
//     transform: rotate(0);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `

// const Center = styled.button`
//   position: absolute;
//   top: ${props => props.click ? '85%' :'50%'  };
//   left: ${props => props.click ? '92%' :'50%'  };
//   transform: translate(-50%,-50%);
//   border: none;
//   outline: none;
//   background-color: transparent;
//   cursor: pointer;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   transition: all 1s ease;

//   &>:first-child{
//     animation: ${rotate} infinite 1.5s linear;
//   }

//   &>:last-child{
//     display: ${props => props.click ? 'none' :'inline-block'  };
//     padding-top: 1rem;
//   }
// `

const DarkDiv = styled.div`
  position: absolute;
  top: 0;
  background-color: #000;
  bottom: 0;
  right: 50%;
  width: ${props => props.click ? '50%' : '0%'};
  height: ${props => props.click ? '100%' : '0%'};
  z-index: 1;
  transition: height 0.5s ease, width 1s ease 0.5s;

  @media only screen and (max-width: 600px) {
    top: 0;
    right: 0;
    bottom: 50%;
    width: ${props => props.click ? '100%' : '0%'};
    height: ${props => props.click ? '40%' : '0%'};
  }
`


const Main = () => {
  const [click, setClick] = useState(true);
  const handleClick = () => setClick(!click);

  return (
    <MainContainer>
      <DarkDiv   click={click}/>
        <Container>
          <LogoComponent theme={click ? 'dark' :'light'}/>
          <SocialIcons theme={click ? 'dark' :'light'} />
          {/* <Contact target="_blank" to={{pathname:"mailto:e.staniulyte98@gmail.com"}}>
            <motion.h2
              initial={{
                y:-200,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              animate={{
                y:0,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
            >
              Say hi..
            </motion.h2>
          </Contact> */}
          <BLOG to="/projects">
            <motion.h2
            initial={{
                y:-200,
                transition: { type:'spring', duration: 1.5, delay:1}
            }}
            animate={{
                y:0,
                transition: { type:'spring', duration: 1.5, delay:1}
            }}
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.9}}
            >
                Projects
            </motion.h2>
        </BLOG>
        <WORK to="/work" click={+click}>
          <motion.h2
            initial={{
              y:-200,
              transition: { type:'spring', duration: 1.5, delay:1}
            }}
            animate={{
              y:0,
              transition: { type:'spring', duration: 1.5, delay:1}
            }}
            whileHover={{scale: 1.1}}
            whileTap={{scale: 0.9}}
          >
            Work
          </motion.h2>
        </WORK>
        <BottomBar>
          <ABOUT to="/about" click={+click}>
            <motion.h2
              initial={{
                y:200,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              animate={{
                y:0,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
            >
              About
            </motion.h2>
          </ABOUT>
          <SKILLS to="/contact">
            <motion.h2
              initial={{
                y:200,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              animate={{
                y:0,
                transition: { type:'spring', duration: 1.5, delay:1}
              }}
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
            >
              Contact
            </motion.h2>
          </SKILLS>
        </BottomBar>
      </Container>
      {click ? <Intro click={click} /> : null }
    </MainContainer>
  )
}

export default Main
