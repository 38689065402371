export const Photos = [
  {
    name: 'Ernis prie arkos',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1b_x3xjK5ABmvoyZa9yHCtfJxWzKnFytA',
  },
  {
    name: 'Migle su vaiku baznycioj',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1YSS1LmF3tDovZU24LD0_P0x6LPKOpipx',
  },
  {
    name: 'Greta su Makeup',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1FMKCKjdz2CGxmB_dVHW-rshaDQl2rsmy',
  },
  {
    name: 'Kara Norviliskes',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=16AQ146_g2BNlJpg4kVChzFR-YnSWQCKz',
  },
  {
    name: 'Adriana mergvakaris',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1wEm0S2tOsKz4MadB9PEoa--1Zk3VoHch',
  },
  {
    name: 'Natashka mergvakaris',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1quklIdhTO8pouOwbD0eq_JLpQp3yLs13',
  },
  {
    name: 'Ilona su gelem',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1Dqdd_Na9bDahgdBYFuE5Jrem4jKC4aWI',
  },
  {
    name: 'Ernis prie moco',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1Bj0CzqCuaLFsWoRGAz6f4BGYBzXy62Nx',
  },
  {
    name: 'Migle su seima',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=16tifd4IXxe8sSCTv4DwF8Kxi7wJmPBFm',
  },
  {
    name: 'Violeta',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1Tr6J4at4OvAIFxbY1f9ikWF2TMjGVWOC',
  },
  {
    name: 'Marcin',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1P7iTmT_vme5OD8MhdFMPB4UdIc7gnqa_',
  },
  {
    name: 'Ilonos mergvakaris',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1JxDAMv_ZRU_dPW5EAOzVKvfOLE5kJ1rz',
  },
  {
    name: 'Violetos bday',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=16roGe9HjSJ8Uv0lGl6Qn2wc6qRfBJ0eM',
  },
  {
    name: "Kara kaledos'22",
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1FlIHupySZSlq9rb3d2IIWB_y5FU6cALT',
  },
  {
    name: 'Greta Kernave',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1zWTbpcnH59j4aOOa_rG2s1BOCYDwAVc0',
  },
  {
    name: 'Martynas',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=16qoxOhimg7IK2K1RllOgjh7TjiZRxh3j',
  },
  {
    name: 'Ilona su ziedu',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1PVm3Vzu8aRSFlrEHiwUx8Jg7SQWnQTnq',
  },
  {
    name: 'Mocas',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1wWDektv99JClfaV5wbSK8EsKp3i8J_ti',
  },
  {
    name: '',
    imgSrc:
      'https://drive.google.com/uc?export=download&id=13I73LZNOmMYkqXaBPo9poPTwNBBU4fGl',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1LIGojdjHyNYH-zcMRsuj-avq3m4AwxMr',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1wMadjCxw36P-KeVc3s19UPQgsauqiJ31',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1k0gKz2RlOg60Fe12KhWuKaZQrWMBq4Nm',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1zSuX_KS7op1-MgD93fhV61ScgTqf9aEV',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1NmEdXK77LRYrZCbxy_33cNHWjYAN3sX4',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1Cc9nKEQ2jbVZTFO3TmKpVGuyquvwhU48',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1DslmN_fYASE3Bmv9eQVlouJznA7jUeUa',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1XIFLLIWj4COD4AYUhdxQKcpf-O1qTLr9',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1V0t0jw3pat-MbYWxbawdahHOJYkTnWd3',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1tMM67t2EjQyQ1zyDvYEF0UK4yfkCLpk_',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1h4k4jtkmOW4cP-SyLSlzFUmDsSW8n1Uy',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1OFXfrLScjgkHGYrb-nMPWQFmKOQAVTq8',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1rApMeEOWm-oMb-P_NS4VoRnyyR6M0qld',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1UWFKLCCSBjh9B2glZVZih-OT-3LazNWG',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=18YKf5oQOxC8OcYORvuCIhuD--dIgIdWj',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1m7B-luFrq1pL1QNrVFV-uDPnK7AMC9H6',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1GbaCbiz3Pvz5oeCy7LM87OXh1HGzW21d',
  },
  {
    imgSrc:
      'https://drive.google.com/uc?export=download&id=1P5bp_RbXY-uQ-fYQvvVUVQ-H21Vn_MZT',
  },
];
